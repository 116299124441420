import { graphql } from 'gatsby';
import PostsPage from 'gatsby-theme-blog-core/src/components/posts';

import * as React from 'react';
import Posts from '../../gatsby-theme-blog/components/posts';

const PostsWrapper = ({ location, data }) => {
    const { site, allBlogPost } = data;
    return (
        <Posts
            location={location}
            posts={allBlogPost.nodes}
            siteTitle={site.siteMetadata.title}
            socialLinks={site.siteMetadata.social}
        />
    );
};

export default PostsWrapper;

// export default PostsPage;

export const query = graphql`
    query PostsQueryShadow($limit: Int!, $filter: BlogPostFilterInput) {
        site {
            siteMetadata {
                title
                social {
                    name
                    url
                }
            }
        }
        allBlogPost(
            sort: { fields: [date, title], order: DESC }
            filter: $filter
            limit: $limit
        ) {
            nodes {
                id
                excerpt
                slug
                title
                date(formatString: "MMMM DD, YYYY")
                tags
                image {
                    childImageSharp {
                        gatsbyImageData(width: 1800)
                    }
                }
                imageAlt
                imageCaptionText
                imageCaptionLink
            }
        }
    }
`;
