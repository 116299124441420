/** @jsx jsx */
import { Themed, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { PalanquinBold } from '../../components/global-styles.component';

const PostHeading = styled.h2`
    ${PalanquinBold};
    ${tw`w-full text-2xl my-4`}
`;

const PostLink = ({ title, slug, date, excerpt, ...post }) => {
    return (
        <article css={tw`mb-12`}>
            <GatsbyImage
                image={getImage(post.image)}
                alt={post.imageAlt ? post.imageAlt : excerpt}
            />
            <header>
                <PostHeading>
                    <Themed.a
                        as={Link}
                        sx={{
                            textDecoration: `none`
                        }}
                        css={tw`text-text`}
                        to={slug}
                    >
                        {title || slug}
                    </Themed.a>
                </PostHeading>
            </header>
            <section>
                <Themed.p css={tw`text-text text-base`}>{excerpt}</Themed.p>
                <Themed.a
                    as={Link}
                    sx={{
                        textDecoration: `none`
                    }}
                    css={tw`text-brandOrange text-base font-bold`}
                    to={slug}
                >
                    Read more
                </Themed.a>
            </section>
        </article>
    );
};

export default PostLink;
