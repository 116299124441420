import React, { Fragment } from 'react';
import { Themed, css } from 'theme-ui';
import tw from 'twin.macro';

const FooterLink = props => (
    <Themed.a
        {...props}
        css={tw`inline text-text lowercase font-bold hover:text-brandOrange transition-all`}
    />
);

const Footer = ({ socialLinks }) => (
    <footer css={tw`text-text text-center mt-8 md:mt-24`}>
        © {new Date().getFullYear()}
        {` `}
        <FooterLink href="https://keyhive.io">Keyhive.io</FooterLink>
        {` `}&bull;{` `}
        <FooterLink href="https://twitter.com/keyhiveio" target="_blank">
            Twitter
        </FooterLink>
        {` `}&bull;{` `}
        <FooterLink
            href="https://www.linkedin.com/company/keyhive-io/"
            target="_blank"
        >
            LinkedIn
        </FooterLink>
    </footer>
);
export default Footer;
