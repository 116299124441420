/** @jsx jsx */
import React from 'react';
import { Themed, jsx } from 'theme-ui';
import tw, { theme } from 'twin.macro';
import PostLink from './post-link';
import PostLinkHero from './post-link-hero';

const PostList = ({ posts }) => {
    const heroPost = posts[0];
    return (
        <React.Fragment>
            <PostLinkHero {...heroPost} />

            <ul
                css={tw`m-0 p-0 grid grid-cols-1 md:grid-cols-3 md:gap-8 auto-rows-max`}
            >
                {posts.slice(1).map(node => (
                    <PostLink key={node.slug} {...node} />
                ))}
            </ul>
        </React.Fragment>
    );
};

export default PostList;
