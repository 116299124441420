/** @jsx jsx */
import { Themed, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import { PalanquinBold } from '../../components/global-styles.component';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

const PostHeading = styled.h3`
    ${PalanquinBold};
    ${tw`w-full text-base mb-4 leading-snug`}
`;

const PostLink = ({ title, slug, date, excerpt, ...post }) => {
    return (
        <li css={tw`mb-4 box-border flex flex-col`}>
            <div css={tw`p-4`}>
                <GatsbyImage
                    image={getImage(post.image)}
                    alt={post.imageAlt ? post.imageAlt : excerpt}
                />
            </div>
            <div css={tw`bg-gray-100 p-4 pt-16 -mt-16 flex-grow`}>
                <header>
                    <PostHeading>
                        <Themed.a
                            as={Link}
                            sx={{
                                textDecoration: `none`
                            }}
                            css={tw`text-text`}
                            to={slug}
                        >
                            {title || slug}
                        </Themed.a>
                    </PostHeading>
                </header>
                <section css={tw`flex flex-col`}>
                    <Themed.p css={tw`text-text text-sm block flex-grow`}>
                        {excerpt}
                    </Themed.p>
                    <Themed.a
                        as={Link}
                        sx={{
                            textDecoration: `none`
                        }}
                        css={tw`text-brandOrange text-sm font-bold justify-self-end mt-auto`}
                        to={slug}
                    >
                        Read more
                    </Themed.a>
                </section>
            </div>
        </li>
    );
};

export default PostLink;
